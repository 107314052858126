
import { Options, Vue, Watch } from "vue-property-decorator";
import * as api from "@/api/qualitycheck.ts";
import File from "@/api/file/index.ts";
import areaSelect from "./areasubmit.vue";
import responsibilityperson from "./responsibility.vue";

@Options({
  components: {
    areaSelect,
    responsibilityperson,
  },
})
export default class extends Vue {
  private areaIdscolumns: any[] = [];
  private resultname = "";
  private uploader: any[] = [];
  private resultPicker = false;
  private flag = false;
  private resultPickerscolumns = [
    { id: 1, name: "正常" },
    { id: 2, name: "异常" },
  ];

  protected goBack() {
    this.$store.commit("project/set_Qualitysafe", {});
    this.$router.go(-1);
  }

  private submitform: any = {
    title: "",
    remark: "",
    areaId: "",
    result: "",
    fileIds: "",
    changePersonId: "",
  };
  private changePersonName = "";

  @Watch("uploader", { deep: true })
  watchFile(files: any) {
    let obj = files.find((file: any) => {
      return file.status === "uploading";
    });
    if (!obj) {
      this.flag = false;
    } else {
      this.flag = true;
    }
  }

  mounted() {
    this.submitform.title = this.$store.state.project.qualitysafe.title
      ? this.$store.state.project.qualitysafe.title
      : "";
    this.submitform.remark = this.$store.state.project.qualitysafe.remark
      ? this.$store.state.project.qualitysafe.remark
      : "";
    this.submitform.result = this.$store.state.project.qualitysafe.resultid
      ? this.$store.state.project.qualitysafe.resultid
      : "";
    this.resultname = this.$store.state.project.qualitysafe.resultname
      ? this.$store.state.project.qualitysafe.resultname
      : "";
    this.initareaIds();
  }

  initareaIds() {
    api
      .projectArealist({
        projectId: this.$store.state.project.projectinfo.projectId,
      })
      .then((res: any) => {
        if (res.code === 0) {
          this.areaIdscolumns = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
  }

  protected resultPickersonConfirm(val: any) {
    this.submitform.result = val.id;
    this.resultname = val.name;
    this.resultPicker = false;
  }

  protected selectPerson(val: any) {
    this.submitform.changePersonId = val;
  }

  protected selectPersonName(val: any) {
    this.changePersonName = val;
  }

  protected areaConfirm(val: any) {
    this.submitform.areaId = val;
  }

  protected onSubmit() {
    let arr = "";
    this.uploader.map((item) => {
      arr += "," + item.id;
    });
    this.submitform.fileIds = arr.substr(1, arr.length - 1);
    api
      .saveCheck({
        projectId: this.$store.state.project.projectinfo.projectId,
        ...this.submitform,
      })
      .then((res: any) => {
        if (res.code == 0) {
          this.$toast.success({
            message: res.msg,
            forbidClick: true,
            onClose: () => {
              this.$store.commit("project/set_Qualitysafe", {});
              this.$router.push("/app/qualitycheck");
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      })
      .catch((err) => {
        // console.log('err')
      });
  }

  afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }

    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "inspectionmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        file.id = res.data.id;
      });
    });
  }
}
